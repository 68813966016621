<template>
  <div>
    <header class="saas2 loding-header nav-abs custom-scroll">
      <div class="container">
        <div class="row">
          <div class="col">
            <nav>
              <a class="d-inline-block m-r-auto" href="https://www.advisorbob.com/" target="_blank">
                <img alt="" class="img-fluid" src="../../assets/advisorbob/images/logo-light.png">
              </a>

              <div class="responsive-btn">
                <a class="toggle-nav" href="#">
                  <i aria-hidden="true" class="fa fa-bars text-white"></i>
                </a>
              </div>

              <div class="navbar m-l-auto" id="togglebtn">
                <div class="responsive-btn">
                  <a class="btn-back" href="#">
                    <h5>back</h5>
                  </a>
                </div>
                <ul class="main-menu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#clientportal">Client Portal</a>
                  </li>
                  <li>
                    <a href="#features">Features</a>
                  </li>
                  <li>
                    <a href="#screenshot">Screenshots</a>
                  </li>
                  <li>
                    <a href="#technologies">Technologies</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <!--Nav end-->
    <!--header css start-->
    <section class="saas2 header" id="home">
      <div class="saas2-content">
        <div class="bg saas2-bg">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="center-text">
                  <div>
                    <div class="header-text">
                      <h1>Revolutionary Financial Advisor Payment Software</h1>
                    </div>
                    <div class="header-sub-text">
                      <p class="sub-para text-white">Fee Calculation, RIA Payout Percentages, Expenses, Reporting</p>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="center-text img-desktop">
                  <img alt="Desktop" class="img-fluid " src="../../assets/advisorbob/images/desktop.jpg">
                  <a class="set-abs" href="javascript:void(0)" data-toggle="modal" data-target="#VideoModal">
                    <i class="fa fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="animation-circle-inverse"><i></i><i></i><i></i></div>

          <img alt="" class="img-fluid set-abs background-animate"
               src="../../assets/advisorbob/images/background2.png">

          <div class="wave"></div>

          <div class="center-content set-abs bottom-content">
            <div class="bottom">
              <a class="down" href="#">
                <img alt="" class="img-fluid" src="../../assets/advisorbob/images/down.png">
              </a>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
    </section>
    <!--header css end-->
    <!--feature css start-->
    <section class="saas2 feature booking" id="about">
      <div class="container">
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <div class="title">
              <div class="main-title">
                <h2>About</h2>
              </div>
            </div>
          </div>
          <div class="col-md-5 col-sm-12">
            <div class="center-text justify-content-center">
              <div class="image-container">
                <img alt="" class="img-fluid advance-feature-img"
                     src="../../assets/advisorbob/images/feature.png">
              </div>
            </div>
          </div>
          <div class="col-md-7 col-sm-12">

            <div data-aos="fade" data-aos-duration="1500">
              <div class="center-content justify-content-start">
                <img alt="" class="img-fluid" src="../../assets/advisorbob/images/icon7.png">
                <div class="feature-content">
                  <h5 class="feature-head">Challenge</h5>
                  <p class="feature-para">The COO of a large Registered Investment Advisor (RIA) approached ORT with the problem of their commission and fee calculations taking a week to be completed using excel and macros. The challenge was that the business manager knew which macros to run for which spreadsheets as all calculations varied across a variety of combinations for a firm and advisor. And all calculations, refunds and fees needed to be handled much like an accounting system to keep track of every change to abide by any financial audits. The client needed a solution that would eliminate errors during the calculations and allow for multiple users to handle their offices in one data solution instead of across many spreadsheets.</p>
                  <a class="readmore" href="javascript:void(0)">Read More <i class="fa fa-chevron-circle-down"></i></a>
                </div>
              </div>
            </div>
            <div data-aos="fade" data-aos-duration="1500">
              <div class="center-content justify-content-start">
                <img alt="" class="img-fluid" src="../../assets/advisorbob/images/icon8.png">
                <div class="feature-content">
                  <h5 class="feature-head">Obstacles</h5>
                  <ul class="feature-para">
                    <li>Calculating fees for all advisors was a 3 day process</li>
                    <li>Error in calculations would be realized months after upon comparison of previous fees</li>
                    <li>Process was very time consuming to make corrections when needed</li>
                    <li>Maintenance of fees and corrections were manually completed and difficult to track</li>
                  </ul>

                  <a class="readmore" href="javascript:void(0)">Read More <i class="fa fa-chevron-circle-down"></i></a>
                </div>
              </div>
            </div>
            <div data-aos="fade" data-aos-duration="1500">
              <div class="center-content justify-content-start">
                <img alt="" class="img-fluid" src="../../assets/advisorbob/images/icon9.png">
                <div class="feature-content">
                  <h5 class="feature-head">Solution</h5>
                  <p class="feature-para">Ocean Ring Technologies built a software solution with security as the highest priority due to financial information needing to be confidential and including privileges for delegating reports to managers and specific advisors when needed. AdvisorBOB (Advisor Book of Business) is a revolutionary financial planning software that offers reliable results, while maintaining dynamic flexibility according to an advisor's unique fee model. To utilize this software is to experience efficiency and convenience. That is why a web application representing these powerful features does not only require intuitive user experience but a clear-cut strategy centered around meeting every visitor and reader's need. This application allowed the client to transition from excel and other legacy products by using a customizable solution that tracks costs, expenses, goals, and financial ratios for each of the advisors.</p>

                  <a class="readmore" href="javascript:void(0)">Read More <i class="fa fa-chevron-circle-down"></i></a>
                </div>
              </div>
            </div>
            <div data-aos="fade" data-aos-duration="1500">
              <div class="center-content justify-content-start">
                <img alt="" class="img-fluid" src="../../assets/advisorbob/images/icon10.png">
                <div class="feature-content">
                  <h5 class="feature-head">Benefits</h5>
                  <p class="feature-para">
                    The solution allows the client to securely outsource advisory fee payouts, including investments, insurance, financial planning, and fee-based 401ks. Due to the high demand and number of different payout scenarios the client needed, ORT was able to scale our offering and include additional functionality. <br>
                    One of the first RIA software systems to include custom transactional importing from multiple sources. Fully supports multiple Rep ID splits, payout adjustments, firm level overrides, admin/program fees for money management firms, and a valuation report.
                  </p>

                  <a class="readmore" href="javascript:void(0)">Read More <i class="fa fa-chevron-circle-down"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--feature css end-->
    <!--business css start-->
    <section class="saas2 bg-gradient quick-sol" id="clientportal">
      <div class="container set-center-div">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 align-self-center">
            <div class="offers-container" data-aos="fade" data-aos-duration="1500">
              <h3 class="text-white offer-heading-text">Client Portal</h3>
              <div class="bottom-border"></div>
              <div class="offers-content">
                <div class="d-flex">
                  <p class="offer-sub-text text-white">AdvisorBob© (Advisor Book of Business) is a revolutionary financial advisor software application that allows firms to move away from excel using a soluction that tracks cost, expenses, goals and buy/sell ratios for each of their advisors. One of the first RIA software systems to include custom transactional importing from multiple sources including Salesforce.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="set-abs clientportal">
        <img alt="" class="img-fluid" data-tilt data-tilt-max="3" data-tilt-perspective="500" data-tilt-speed="400" src="../../assets/advisorbob/images/img.png">
      </div>
    </section>
    <!--business css end-->
    <!--service css start-->
    <section class="services" id="features">
      <div class="container saas2-services">
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <div class="title">
              <div class="main-title">
                <h2>Features</h2>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 service-container">
            <div class="service service-overlay" data-tilt data-tilt-max="6" data-tilt-perspective="500" data-tilt-speed="400">
              <div class="img-block">
                <!-- <i class="fa fa-dashboard"><span></span></i> -->
                <img src="../../assets/advisorbob/images/icon1.png" class="img-fluid" alt="Services Icon">
              </div>
              <div class="service-feature">
                <h4 class="feature-text">Dashboard</h4>
                <p>Interactive user interface that provides entire firm overview or individual advisor overview. Provides net revenue, ticket charges, and total accounts. Provides a comparison between current month vs previous month net revenue, ticket charge, and expenses.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 service-container">
            <div class="service service-overlay" data-tilt data-tilt-max="6" data-tilt-perspective="500" data-tilt-speed="400">
              <div class="img-block">
                <img src="../../assets/advisorbob/images/icon2.png" class="img-fluid" alt="Services Icon">
              </div>
              <div class="service-feature">
                <h4 class="feature-text">Fee Details</h4>
                <p>Advisors and administrators can view each line item affecting total commission payment on a line-by-line basis showing the gross fee, admin or program fees, and payout percentages resulting in a net amount. AdvisorBOB can aggregate commissions from any number of vendors into one table.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 service-container">
            <div class="service service-overlay" data-tilt data-tilt-max="6" data-tilt-perspective="500" data-tilt-speed="400">
              <div class="img-block">
                <img src="../../assets/advisorbob/images/icon3.png" class="img-fluid" alt="Services Icon">
              </div>
              <div class="service-feature">
                <h4 class="feature-text">Financial Plans</h4>
                <p>AdvisorBOB will keep track of all one-time and recurring consulting agreements or financial plans charged by members of your firm. Administrators can keep records of each plan payment and store plan information as part of this all-in-one payment software.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 service-container">
            <div class="service service-overlay" data-tilt data-tilt-max="6" data-tilt-perspective="500" data-tilt-speed="400">
              <div class="img-block">
                <img src="../../assets/advisorbob/images/icon4.png" class="img-fluid" alt="Services Icon">
              </div>
              <div class="service-feature">
                <h4 class="feature-text">Adjustments</h4>
                <p>If your RIA charges advisors for services outside of their standard payout percentage (desk fees, virtual admin services, marketing) the Adjustments feature allows you to add a one-off or recurring expense to an advisor’s commission. Adjustments can be used for expense or credit items.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 service-container">
            <div class="service service-overlay" data-tilt data-tilt-max="6" data-tilt-perspective="500" data-tilt-speed="400">
              <div class="img-block">
                <img src="../../assets/advisorbob/images/icon5.png" class="img-fluid" alt="Services Icon">
              </div>
              <div class="service-feature">
                <h4 class="feature-text">Administrator</h4>
                <p>The Administrators of your RIA will have access to the “heart” of AdvisorBOB where you can easily change any Advisor’s payout percentage, add manager overrides, send announcements to all users, and most importantly, import transaction data from vendors each pay cycle.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 service-container">
            <div class="service service-overlay" data-tilt data-tilt-max="6" data-tilt-perspective="500" data-tilt-speed="400">
              <div class="img-block">
                <img src="../../assets/advisorbob/images/icon6.png" class="img-fluid" alt="Services Icon">
              </div>
              <div class="service-feature">
                <h4 class="feature-text">Reports</h4>
                <p>Do you know which of your advisors are the most valuable to your firm? With the click of a button administrators can run a report to see their profitability on each individual advisor or the firm as a whole, total net payouts by rep ID or Vendor for any pay cycle, ticket charges, and more.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--service css end-->
    <!--slider css start-->
    <section class="saas2 laptop-slider bg-gradient" id="screenshot">
      <div class="container">
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <div class="title">
              <div class="main-title">
                <h2 class="text-white">take a closer look</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="slider-container">
          <carousel
                  class="trainers-slider owl-carousel owl-theme"
                  :margin="0"
                  :nav="false"
                  :autoHeight="true"
                  :center="true"
                  :slideSpeed="300"
                  :paginationSpeed="400"
                  :dots="true"
                  :responsive="{0:{items:1},600:{items:2},900:{items:3}}"
                  :loop="true"
                  :autoplay="true"
          >
            <div class="item">
              <img alt="" class="img-fluid" src="../../assets/advisorbob/images/screen1.jpg">
            </div>
            <div class="item">
              <img alt="" class="img-fluid" src="../../assets/advisorbob/images/screen2.png">
            </div>
            <div class="item">
              <img alt="" class="img-fluid" src="../../assets/advisorbob/images/screen3.jpg">
            </div>
            <div class="item">
              <img alt="" class="img-fluid" src="../../assets/advisorbob/images/screen4.jpg">
            </div>
          </carousel>
        </div>
      </div>
    </section>
    <!--slider css start-->
    <!--brand css start-->
    <section class="saas2 brand" id="technologies">
      <div class="col-md-10 offset-md-1 col-12">
        <div class="container saas2-services">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="title">
                <div class="main-title">
                  <h2>Technology Stack</h2>
                </div>
              </div>
            </div>
            <div class="col-12">
              <carousel
                class="brand-slider owl-carousel owl-theme"
                :margin="40"
                :nav="false"
                :autoHeight="true"
                :center="true"
                :slideSpeed="300"
                :paginationSpeed="400"
                :dots="false"
                :responsive="{
                  0: {
                    items: 1,
                    margin: 40
                  },
                  320: {
                    items: 1,
                    margin: 30
                  },
                  360: {
                    items: 2,
                    margin: 20
                  },
                  480: {
                    items: 3,
                    margin: 30
                  },
                  768: {
                    items: 4,
                    margin: 30
                  },
                  992: {
                    items: 5
                  }
                }"
                :loop="true"
                :autoplay="true"
              >
                <div class="item">
                  <a href="#">
                    <img alt="" class="img-fluid" src="../../assets/advisorbob/images/brand1.png">
                  </a>
                </div>
                <div class="item">
                  <a href="#">
                    <img alt="" class="img-fluid" src="../../assets/advisorbob/images/brand2.png">
                  </a>
                </div>
                <div class="item">
                  <a href="#">
                    <img alt="" class="img-fluid" src="../../assets/advisorbob/images/brand3.png">
                  </a>
                </div>
                <div class="item">
                  <a href="#">
                    <img alt="" class="img-fluid" src="../../assets/advisorbob/images/brand4.png">
                  </a>
                </div>
                <div class="item">
                  <a href="#">
                    <img alt="" class="img-fluid" src="../../assets/advisorbob/images/brand5.png">
                  </a>
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--brand css end-->
    <!--footer start-->
    <footer class="saas2 footer2" id="contact">
      <div class="container footer-padding">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="logo-sec">
              <div class="footer-title mobile-title p-t-0">
                <h3 class="text-white">About Us</h3>
              </div>
              <div class="footer-contant">
                <a href="/Projects">
                  <img alt="" class="img-fluid footer-logo" src="../../assets/img/logo.png">
                </a>
                <div class="footer-para">
                  <h6 class="text-white para-address">
                    <i class="fa fa-map-marker"></i> 701 S. 50th St Philadelphia, PA
                  </h6>
                  <h6 class="text-white para-address"><i class="fa fa-envelope"></i> &#105;&#110;&#102;&#111;&#64;&#111;&#99;&#101;&#97;&#110;&#114;&#105;&#110;&#103;&#116;&#101;&#99;&#104;&#46;&#99;&#111;&#109;</h6>
                  <h6 class="text-white para-address"><i class="fa fa-phone"></i> &#40;&#56;&#53;&#54;&#41;&#32;&#52;&#52;&#56;&#45;&#52;&#51;&#54;&#56;</h6>
                </div>
                <ul class="d-d-flex footer-social social">
                  <li class="footer-social-list">
                    <a href="https://www.facebook.com/oceanringtech/" target="_blank">
                      <i aria-hidden="true" class="fa fa-facebook"></i>
                    </a>
                  </li>

                  <li class="footer-social-list">
                    <a href="https://twitter.com/oceanringtech" target="_blank">
                      <i aria-hidden="true" class="fa fa-twitter"></i>
                    </a>
                  </li>

                  <li class="footer-social-list">
                    <a href="https://www.linkedin.com/company/14395475" target="_blank">
                      <i aria-hidden="true" class="fa fa-linkedin"></i>
                    </a>
                  </li>

                  <li class="footer-social-list">
                    <a href="https://www.instagram.com/oceanringtech/" target="_blank">
                      <i aria-hidden="true" class="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="footer-title mobile-title">
              <h3 class="text-white">Product</h3>
            </div>
            <div class="footer-contant">
              <h5 class="footer-headings">Product</h5>
              <div class="footer-para">
                <p class="text-white">Financial advisor software application that allows firms to calculate the proper program fee for billing client accounts and promptly process advisor payments.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="footer-title mobile-title">
              <h3 class="text-white">Company</h3>
            </div>
            <div class="footer-contant">
              <h5 class="footer-headings">Company</h5>
              <div class="footer-para">
                <p class="text-white">We are a software solutions company helping clients develop, manage and modernize applications to accelerate their digital transformation and business results.</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="footer-title mobile-title">
              <h3 class="text-white">Subscribe our newsletter</h3>
            </div>
            <div class="footer-contant">
              <h5 class="footer-headings">newsletter</h5>
              <p class="text-white">Subscribe to our newsletter to keep up to date. We will not send you spam email.</p>
              <!-- Begin Mailchimp Signup Form -->
              <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css">

              <div id="mc_embed_signup">
                <form action="https://oceanringtech.us4.list-manage.com/subscribe/post?u=e3e1dfb0375e0ab1d648fc68b&amp;id=a738f8deb7" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                  <div id="mc_embed_signup_scroll">
                    <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Email Address" required>
                    <div style="position: absolute;left: -5000px;" aria-hidden="true">
                      <input type="text" name="b_7be75501e45a6863484b49f65_02acd75f7f" tabindex="-1" value="">
                    </div>
                    <div class="clear">
                      <button type="submit" name="subscribe" id="mc-embedded-subscribe" class="button">
                        <i class="fa fa-paper-plane"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <!--End mc_embed_signup-->
              <div>
                <h4 class="text-white foot-text">AdvisorBOB</h4>
                <h6 class="text-white foot-copyright-text">&copy; Copyright {{new Date().getFullYear()}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!--footer end-->
    <!-- Tap on Top-->
    <div class="tap-top">
      <div><i class="fa fa-angle-double-up"></i></div>
    </div>
    <!-- Tap on Ends-->
    <!-- Video Modal Start -->
    <div class="modal fade theme-modal" id="VideoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/kxPv-ULkxGs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- Video Modal End -->
  </div>
</template>

<script>
  import $ from "jquery";
  import carousel from 'vue-owl-carousel';

  export default {
    name: "Advisorbob",
    components: {
      carousel
    },
    mounted() {
      $(function() {
        $('.down').click (function() {
          $('html, body').animate({scrollTop: $('.booking').offset().top }, 'slow');
          return false;
        });
      });

      if ($(window).width() <= 991) {
        $('.menu-container').find('.inner-collapse').addClass("collapse");
        $(".dropdown-sec").on('click', function () {
          $('.navbar-nav').find('.dropdown-content').removeClass("demo");
          $(this).find('.dropdown-content').addClass("demo");
        });

        $(".menu-container").on('click', function () {
          $('.menu-container').find('.inner-collapse').removeClass("demo2");
          $(this).find('.inner-collapse').removeClass("collapse");
          $(this).find('.inner-collapse').addClass("demo2");
        });

      }

      $(window).on('scroll', function () {
        if ($(this).scrollTop() > 500) {
          $('.tap-top').fadeIn();
        } else {
          $('.tap-top').fadeOut();
        }
      });
      $('.tap-top').on('click', function () {
        $("html, body").animate({
          scrollTop: 0
        }, 600);
        return false;
      });

      if ($(window).width() >= 991) {
        $("nav a").on('click', function (event) {
          if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
              scrollTop: $(hash).offset().top - 45
            }, 1000, function () {
            });
            return false;
          }
        });
      } else {
        $("nav a").on('click', function (event) {
          if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
              scrollTop: $(hash).offset().top - 50
            }, 1000, function () {
            });
            return false;
          }
        });
      }

      $('.toggle-nav').on('click', function(e) {
        $('.navbar').css("right","0px");
      });
      $('.btn-back').on('click', function(e) {
        $('.navbar').css("right","-350px");
      });
      function checkWidth() {
        var windowSize = $(window).width();
        if (windowSize <= 991) {
          $(".dropdown").click(function(){
            $(".dropdown ~ul").not($(this).siblings()).hide("fast");
            $(".dropdown ~.mega-menu-container").not($(this).siblings()).hide("fast");
            $(this).siblings().slideToggle("fast");
          });
        }
        else if (windowSize >= 991) {
          $(".navbar > ul > li").mouseover(function(){
            $(this).children().css("display", "block");
          });
          $(".navbar > ul > li").mouseout(function(){
            $(this).children().last().css("display", "none");
            $(this).children("a").css("display", "block");
          });

          $(".sub-menu").mouseover(function(){
            $(this).children().css("display", "block")
          });
          $(".sub-menu").mouseout(function(){
            $(this).children().last().css("display", "none")
          });
        }
      }
      checkWidth();
      $(window).resize(checkWidth);
    }
  }
</script>

<style scoped>
  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
  @import url('../../assets/advisorbob/css/bootstrap.css');
  @import url('../../assets/advisorbob/css/fontawesome.css');
  @import url('../../assets/advisorbob/css/style.css');

  .owl-carousel {
    display: inherit;
  }
</style>
